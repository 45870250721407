var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-dialog', {
    attrs: {
      "max-width": "600px",
      "persistent": ""
    },
    model: {
      value: _vm.isOpen,
      callback: function callback($$v) {
        _vm.isOpen = $$v;
      },
      expression: "isOpen"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "primary white--text"
  }, [_vm._v(" Job Filters ")]), _c('v-card-text', {
    staticClass: "mt-4",
    staticStyle: {
      "max-height": "75vh"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('LazyDropdown', {
    attrs: {
      "url": "job_types",
      "label": "Job Type",
      "item-text": "name",
      "item-value": "xid",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.job_type,
      callback: function callback($$v) {
        _vm.job_type = $$v;
      },
      expression: "job_type"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('LazyDropdown', {
    attrs: {
      "url": "customers",
      "label": "Customer",
      "item-text": "name",
      "item-value": "xid",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.customer,
      callback: function callback($$v) {
        _vm.customer = $$v;
      },
      expression: "customer"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm.customer ? [_vm.customer ? _c('LazyDropdown', {
    attrs: {
      "url": '/customers/' + _vm.customer.xid + '/staff',
      "placeholder": "Contact",
      "item-text": function itemText(_ref) {
        var user = _ref.user;
        return "".concat(user.name_first, " ").concat(user.name_last);
      },
      "item-value": "xid",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.contact,
      callback: function callback($$v) {
        _vm.contact = $$v;
      },
      expression: "contact"
    }
  }) : _vm._e()] : _vm._e()], 2), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.openedChoices,
      "label": "Date Opened",
      "outlined": "",
      "dense": "",
      "clearable": "",
      "hide-details": ""
    },
    model: {
      value: _vm.opened,
      callback: function callback($$v) {
        _vm.opened = $$v;
      },
      expression: "opened"
    }
  }), _vm.opened == 'Range' ? [_c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "offset-y": "",
      "max-width": "290px",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          staticClass: "mt-2",
          attrs: {
            "outlined": "",
            "dense": "",
            "hide-details": "",
            "value": _vm.opened_date_formatted,
            "readonly": ""
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }], null, false, 1997374069),
    model: {
      value: _vm.opened_show,
      callback: function callback($$v) {
        _vm.opened_show = $$v;
      },
      expression: "opened_show"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "range": "",
      "no-title": ""
    },
    model: {
      value: _vm.opened_range,
      callback: function callback($$v) {
        _vm.opened_range = $$v;
      },
      expression: "opened_range"
    }
  })], 1)] : _vm._e()], 2), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.dueChoices,
      "label": "Date Due",
      "outlined": "",
      "dense": "",
      "clearable": "",
      "hide-details": ""
    },
    model: {
      value: _vm.due,
      callback: function callback($$v) {
        _vm.due = $$v;
      },
      expression: "due"
    }
  }), _vm.due == 'Range' ? [_c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "offset-y": "",
      "max-width": "290px",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref3) {
        var on = _ref3.on,
          attrs = _ref3.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          staticClass: "mt-2",
          attrs: {
            "outlined": "",
            "dense": "",
            "hide-details": "",
            "value": _vm.due_date_formatted,
            "readonly": ""
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }], null, false, 2659768628),
    model: {
      value: _vm.due_show,
      callback: function callback($$v) {
        _vm.due_show = $$v;
      },
      expression: "due_show"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "range": "",
      "no-title": ""
    },
    model: {
      value: _vm.due_range,
      callback: function callback($$v) {
        _vm.due_range = $$v;
      },
      expression: "due_range"
    }
  })], 1)] : _vm._e()], 2), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('LazyDropdown', {
    attrs: {
      "url": "jobStatus",
      "label": "Current Status",
      "item-text": "name",
      "item-value": "xid",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.status,
      callback: function callback($$v) {
        _vm.status = $$v;
      },
      expression: "status"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.status_updated_choices,
      "label": "Status Changed",
      "outlined": "",
      "dense": "",
      "clearable": "",
      "hide-details": ""
    },
    model: {
      value: _vm.status_updated,
      callback: function callback($$v) {
        _vm.status_updated = $$v;
      },
      expression: "status_updated"
    }
  }), _vm.status_updated == 'Range' ? [_c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "offset-y": "",
      "max-width": "290px",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref4) {
        var on = _ref4.on,
          attrs = _ref4.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          staticClass: "mt-2",
          attrs: {
            "outlined": "",
            "dense": "",
            "hide-details": "",
            "value": _vm.status_updated_range_formatted,
            "readonly": ""
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }], null, false, 1475698997),
    model: {
      value: _vm.status_updated_show,
      callback: function callback($$v) {
        _vm.status_updated_show = $$v;
      },
      expression: "status_updated_show"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "range": "",
      "no-title": ""
    },
    model: {
      value: _vm.status_updated_datepicker,
      callback: function callback($$v) {
        _vm.status_updated_datepicker = $$v;
      },
      expression: "status_updated_datepicker"
    }
  })], 1)] : _vm._e()], 2), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('LazyDropdown', {
    attrs: {
      "url": "qms/steps",
      "label": "Manufacture Step",
      "item-text": "title",
      "item-value": "xid",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.step,
      callback: function callback($$v) {
        _vm.step = $$v;
      },
      expression: "step"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('LazyDropdown', {
    attrs: {
      "url": "dispatch/status",
      "label": "Current Despatch Status",
      "item-text": "status",
      "item-value": "xid",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.dispatch,
      callback: function callback($$v) {
        _vm.dispatch = $$v;
      },
      expression: "dispatch"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.status_updated_choices,
      "label": "Last Changed",
      "outlined": "",
      "dense": "",
      "clearable": "",
      "hide-details": ""
    },
    model: {
      value: _vm.dispatch_updated,
      callback: function callback($$v) {
        _vm.dispatch_updated = $$v;
      },
      expression: "dispatch_updated"
    }
  }), _vm.dispatch_updated == 'Range' ? [_c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "offset-y": "",
      "max-width": "290px",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref5) {
        var on = _ref5.on,
          attrs = _ref5.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          staticClass: "mt-2",
          attrs: {
            "outlined": "",
            "dense": "",
            "hide-details": "",
            "value": _vm.dispatch_updated_range_formatted,
            "readonly": ""
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }], null, false, 2750881329),
    model: {
      value: _vm.dispatch_updated_show,
      callback: function callback($$v) {
        _vm.dispatch_updated_show = $$v;
      },
      expression: "dispatch_updated_show"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "range": "",
      "no-title": ""
    },
    model: {
      value: _vm.dispatch_updated_datepicker,
      callback: function callback($$v) {
        _vm.dispatch_updated_datepicker = $$v;
      },
      expression: "dispatch_updated_datepicker"
    }
  })], 1)] : _vm._e()], 2), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('LazyDropdown', {
    attrs: {
      "url": "billing/status",
      "label": "Current Billing Status",
      "item-text": "status",
      "item-value": "xid",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.payment_status,
      callback: function callback($$v) {
        _vm.payment_status = $$v;
      },
      expression: "payment_status"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.status_updated_choices,
      "label": "Last Changed",
      "outlined": "",
      "dense": "",
      "clearable": "",
      "hide-details": ""
    },
    model: {
      value: _vm.payment_updated,
      callback: function callback($$v) {
        _vm.payment_updated = $$v;
      },
      expression: "payment_updated"
    }
  }), _vm.payment_updated == 'Range' ? [_c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "offset-y": "",
      "max-width": "290px",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref6) {
        var on = _ref6.on,
          attrs = _ref6.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          staticClass: "mt-2",
          attrs: {
            "outlined": "",
            "dense": "",
            "hide-details": "",
            "value": _vm.payment_updated_range_formatted,
            "readonly": ""
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }], null, false, 2423817819),
    model: {
      value: _vm.payment_updated_show,
      callback: function callback($$v) {
        _vm.payment_updated_show = $$v;
      },
      expression: "payment_updated_show"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "range": "",
      "no-title": ""
    },
    model: {
      value: _vm.payment_updated_datepicker,
      callback: function callback($$v) {
        _vm.payment_updated_datepicker = $$v;
      },
      expression: "payment_updated_datepicker"
    }
  })], 1)] : _vm._e()], 2)], 1)], 1), _c('v-divider'), _c('v-card-actions', {
    staticClass: "grey lighten-3"
  }, [_c('v-spacer'), _c('v-btn', {
    staticClass: "primary",
    on: {
      "click": _vm.clickDone
    }
  }, [_vm._v("Done")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }