var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.LOGGED_IN ? _c('div', {
    attrs: {
      "id": "page"
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "11"
    }
  }, [_c('Education', [_vm._v(" Here are some tips about this page. ")]), _c('v-card', {
    attrs: {
      "rounded": "",
      "disabled": _vm.loading
    }
  }, [_c('v-data-table', {
    staticClass: "transparent",
    attrs: {
      "loading": _vm.loading,
      "headers": _vm.headers,
      "items": _vm.jobs,
      "dense": "",
      "server-items-length": _vm.jobs_total,
      "options": _vm.options,
      "footer-props": {
        'items-per-page-options': [10, 20, 30, 40, 50],
        'show-first-last-page': true,
        'show-current-page': true
      }
    },
    on: {
      "click:row": _vm.clickJobRow,
      "update:options": function updateOptions($event) {
        _vm.options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('v-sheet', {
          staticClass: "primary rounded px-3 pt-3 red--text"
        }, [_c('v-row', {}, [_c('v-col', {
          staticClass: "d-flex align-center",
          attrs: {
            "rows": "12",
            "md": "6",
            "lg": "4",
            "xl": "3"
          }
        }, [_c('v-text-field', {
          attrs: {
            "value": _vm.search,
            "dark": "",
            "dense": "",
            "rounded": "",
            "outlined": "",
            "hide-details": "",
            "placeholder": "search",
            "prepend-icon": "mdi-magnify"
          },
          on: {
            "change": function change(v) {
              _vm.search = v;
            }
          }
        }), _c('v-btn', {
          staticClass: "ml-2",
          attrs: {
            "icon": "",
            "dark": ""
          },
          on: {
            "click": _vm.clickFilter
          }
        }, [_c('v-icon', [_vm._v("mdi-filter")])], 1)], 1), _c('v-spacer'), _c('v-col', {
          staticClass: "text-right",
          attrs: {
            "rows": "12",
            "md": "6",
            "lg": "4",
            "xl": "3"
          }
        }, [_c('v-btn', {
          staticClass: "white--text",
          attrs: {
            "rounded": "",
            "outlined": ""
          },
          on: {
            "click": _vm.clickNewJob
          }
        }, [_c('v-icon', [_vm._v("mdi-plus")]), _vm._v("New")], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "item.description",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [item.description ? _c('span', [_vm._v(_vm._s(item.description))]) : _c('span', [_c('i', {
          staticClass: "grey--text"
        }, [_vm._v("No Description")])])];
      }
    }, {
      key: "item.job_type",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.job_type ? _c('span', [_vm._v(_vm._s(item.job_type.name))]) : _vm._e()];
      }
    }, {
      key: "item.customer",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {}, [item.customer ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var on = _ref4.on,
                attrs = _ref4.attrs;
              return [_c('div', _vm._g(_vm._b({}, 'div', attrs, false), on), [_vm._v(_vm._s(item.customer.name))])];
            }
          }], null, true)
        }, [item.ship_to ? _c('span', [_vm._v(_vm._s(item.ship_to.company_name)), _c('br'), _vm._v(_vm._s(item.ship_to.city_locality) + " " + _vm._s(item.ship_to.state_province))]) : _c('span', {
          staticClass: "font-italic"
        }, [_vm._v("No Delivery Location")])]) : _c('span', [_c('i', {
          staticClass: "grey--text"
        }, [_vm._v("Not Assigned")])])], 1)];
      }
    }, {
      key: "item.contact",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [item.contact ? _c('span', [_vm._v(_vm._s(item.contact.user.name_first) + " " + _vm._s(item.contact.user.name_last))]) : _c('span', [_c('i', {
          staticClass: "grey--text"
        }, [_vm._v("Not Assigned")])])];
      }
    }, {
      key: "item.created_at",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('Moment', {
          attrs: {
            "timestamp": item.created_at,
            "xmode": "calendar",
            "tooltip": "",
            "tooltipType": "datetime"
          }
        })];
      }
    }, {
      key: "item.date_due",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [item.date_due ? _c('Moment', {
          attrs: {
            "timestamp": item.date_due,
            "xmode": "calendar"
          }
        }) : _c('span', [_c('i', {
          staticClass: "grey--text"
        }, [_vm._v("-")])])];
      }
    }, {
      key: "item.recent_activity",
      fn: function fn(_ref8) {
        var value = _ref8.value;
        return [value ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref9) {
              var on = _ref9.on,
                attrs = _ref9.attrs;
              return [_c('div', _vm._g(_vm._b({}, 'div', attrs, false), on), [_vm._v(_vm._s(value.task.title))])];
            }
          }], null, true)
        }, [_vm._v(" Assigned to " + _vm._s(value.delegate) + " "), _c('Moment', {
          attrs: {
            "timestamp": value.created_at,
            "mode": "timeAgo"
          }
        })], 1) : _vm._e()];
      }
    }], null, false, 378728065)
  }), _c('JobFilters', {
    ref: "JobFilters",
    on: {
      "filter": _vm.updateJobFilter
    }
  })], 1)], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }