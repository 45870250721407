<template>
  <div v-if='LOGGED_IN' id='page'><v-row justify='center'><v-col cols="12" xl="11">

    <Education>
      Here are some tips about this page.
    </Education>

    <v-card rounded class='' :disabled="loading">
      <v-data-table
        :loading="loading"
        :headers='headers'
        :items='jobs'
        class='transparent'
        @click:row='clickJobRow'
        dense
        :server-items-length="jobs_total"
        :options.sync="options"
        :footer-props="{ 'items-per-page-options':[10,20,30,40,50], 'show-first-last-page': true, 'show-current-page': true }"
      >
      <template v-slot:top>
        <v-sheet class='primary rounded px-3 pt-3 red--text'>
          <v-row class=''>
            <v-col rows=12 md=6 lg=4 xl=3 class="d-flex align-center">
              <v-text-field :value='search' @change="v=>{ search = v; }" dark dense rounded outlined hide-details placeholder='search' prepend-icon="mdi-magnify" />
              <v-btn icon dark class="ml-2" @click="clickFilter"><v-icon>mdi-filter</v-icon></v-btn>
            </v-col>
            <v-spacer />
            <v-col rows=12 md=6 lg=4 xl=3 class='text-right'>
              <v-btn rounded class="white--text" outlined @click='clickNewJob'><v-icon>mdi-plus</v-icon>New</v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      </template>
      <template v-slot:item.description="{item}">
        <span v-if='item.description'>{{item.description}}</span>
        <span v-else><i class='grey--text'>No Description</i></span>
      </template>
      <template v-slot:item.job_type="{item}">
        <span v-if='item.job_type'>{{ item.job_type.name }}</span>
      </template>
      <template v-slot:item.customer="{item}">
        <div class="">
            <v-tooltip bottom v-if='item.customer'>
            <template #activator="{on,attrs}">
              <div v-on='on' v-bind='attrs'>{{item.customer.name}}</div>
            </template>
            <span v-if="item.ship_to">{{item.ship_to.company_name}}<br/>{{ item.ship_to.city_locality }} {{ item.ship_to.state_province }}</span>
            <span v-else class="font-italic">No Delivery Location</span>
          </v-tooltip>
          <span v-else><i class='grey--text'>Not Assigned</i></span>
        </div>
      </template>
      <template v-slot:item.contact="{item}">
        <span v-if='item.contact'>{{item.contact.user.name_first}} {{item.contact.user.name_last}}</span>
        <span v-else><i class='grey--text'>Not Assigned</i></span>
      </template>
      <template v-slot:item.created_at="{item}">
        <Moment :timestamp='item.created_at' xmode='calendar' tooltip tooltipType="datetime" />
      </template>
      <template v-slot:item.date_due="{item}">
        <Moment v-if='item.date_due' :timestamp='item.date_due' xmode='calendar' />
        <span v-else><i class='grey--text'>-</i></span>
      </template>
      <template v-slot:item.recent_activity="{value}">
          <v-tooltip bottom v-if='value'>
            <template #activator="{on,attrs}">
              <div v-on='on' v-bind='attrs'>{{value.task.title}}</div>
            </template>
            Assigned to {{value.delegate}}
            <Moment :timestamp='value.created_at' mode='timeAgo' />
          </v-tooltip>
      </template>
    </v-data-table>

    <JobFilters ref="JobFilters" @filter="updateJobFilter" />

  </v-card>
  </v-col></v-row>

  <!-- ALT STYLE
  <v-row justify="center" v-for="job in jobs" v-bind:key="job.id" class='my-2'>
    <v-col cols=12 xl=10 class='py-0'>
      <v-sheet rounded elevation="2" class='white rounded job' style='overflow:hidden;' @click='clickJobRow(job)'>
        <v-row class='pa-3 primary white--text' align='center'>
          <v-col cols=2 class='reference'>{{job.ref}}</v-col>
          <v-col cols=8 class=''>
            <span class='description'>{{job.description}}</span><br>
            <span class='clinic'>Adelaide Clinic</span></v-col>
          <v-col cols=2 class=''>
            <span class='status'>{{job.status.status}}</span><br>
            <span class='dueDate'>Due <Moment :timestamp='job.date_due' mode='calendar' /></span><br>
            </v-col>
        </v-row>

      </v-sheet>
    </v-col>
  </v-row>
-->

</div>
</template>

<style lang="scss" scoped>
@import "@/assets/common";



#page{
  .v-data-table::v-deep{
    position: relative;
    table{
      padding: 0px;
      margin: 0px;
    }
    thead{
      background-color: $primary;
      th{
        color: white;
      }
    }
    tr{
      cursor: pointer;
    }
  }
  .job{
    cursor: pointer;
  }
  .reference, .status{
    font-weight: bold;
    font-size: 1.1em;
    text-align: center;
  }
  .description{

  }
  .clinic, .dueDate{
    font-style: italic;
    font-size: 0.9em;
  }
}

</style>


<script>
import { mapActions, mapGetters } from 'vuex'
import Education from '@/components/Education'
import Moment from '@/components/Moment'
import JobFilters from '@/components/Jobs/JobFilters'

export default {
  name: 'Jobs',

  components: {
    Education, Moment, JobFilters
  },

  data: () => ({
    pageReady: false,
    loading: false,
    jobs: [],
    jobs_total: 0,
    search: "",
    filters: { },
    options: {
      page: 1, sortBy: [], sortDesc: [], itemsPerPage: 30
    },
    editCustomer: {
      show: false,
      loading: false,
      name: "test",
      id: null,
    },
  }),

  computed: {
    ...mapGetters('Auth', ['LOGGED_IN', 'TOKEN', 'MODULES']),

    headers(){
      return [
        //{ text: 'ID', value: 'id' },
        { text: 'Job#', value: 'ref', sortable: false },
        { text: 'Type', value: 'job_type', sortable: false },
        { text: 'Job Name', value: 'description',sortable: false },
        { text: 'Customer', value: 'customer', sortable: false },
        { text: 'Contact', value: 'contact', sortable: false },
        { text: 'Status', value: 'status.name', sortable: false, },
        { text: 'Last QMS', value: 'recent_activity', sortable: false, },
        { text: 'Opened', value: 'created_at', sortable: true, align: 'center' },
        { text: 'Due', value: 'date_due', sortable: true, align: 'center' },
      ];
    },

    customersFiltered(){
      return this.customers;
    },

  },

  watch:{
    'options.page'(){ if(this.pageReady) this.loadJobs(); },
    'options.itemsPerPage'(){ if(this.pageReady) this.loadJobs(); },
    'options.sortBy'(){ if(this.pageReady) this.loadJobs(); },
    'options.sortDesc'(){ if(this.pageReady) this.loadJobs(); },
    'search'(){ if(this.pageReady) this.loadJobs(); },
  },

  methods:{
    ...mapActions('UI', ['SHOW_NAVBARS', 'NOTIFY', 'SET_PAGE_TITLE', 'PROMPT', 'NAVIGATE']),
    ...mapActions('Auth', ['REQUIRE_LOGIN']),
    ...mapActions('API', ['API']),

    clickNewJob(){
      //let newJobId = null;
      this.API({method: 'POST', endpoint: 'jobs'})
      .then(id => { this.NAVIGATE(`job/${id}`); });
      //.then(id => { newJobId = id; })
      //.then(this.loadJobs)
      //.then(() => { console.log("New Job:", newJobId); });
      //.then(()=>{ this.editCustomer_show(newCustomerId); });
    },

    loadJobs(){
      this.loading = true;
      let data = {
        ...this.jobFilters,
        page: this.options.page,
        items_per_page: this.options.itemsPerPage,
        search: this.search,
        sort: this.options.sortBy[0] ?? undefined, sortDesc: this.options.sortDesc[0] ?? undefined,
      };
      return this.API({ method: 'POST', endpoint: 'jobs/search', data })
      .then(paginate => {
        this.jobs = paginate.data;
        this.jobs_total = paginate.total;
      })
      .finally(() => { this.loading = false; });
    },

    clickJobRow(job){
      window.open(`/#/job/${job.xid}/`, "_blank");
    },

    searchFilter(value, search, job){
      let items = [job.ref, job.customer?.name, job.description, job.status?.name];
      let terms = search.toLowerCase().split(' ');
      return terms.every(term => {
        return items.findIndex(item => {
          return item?.toLowerCase().includes(term); }) >= 0;
      });
    },

    clickFilter(){ this.$refs.JobFilters.open(); },
    updateJobFilter(filters){
      this.jobFilters = filters;
      this.loadJobs();
    },

  },

  mounted() {
    this.REQUIRE_LOGIN(()=>{
      this.SHOW_NAVBARS()
      this.SET_PAGE_TITLE('Job List');
      this.loadJobs()
      .then(() => { this.pageReady = true; });
    });
  },

};
</script>
