<template>
  <div>
    <v-dialog v-model="isOpen" max-width="600px" persistent>
      <v-card>
        <v-card-title class="primary white--text">
          Job Filters
        </v-card-title>
        <v-card-text style="max-height:75vh;" class="mt-4">

          <v-row>

            <v-col cols="6">
              <LazyDropdown url="job_types" v-model="job_type" label="Job Type" item-text="name" item-value="xid" outlined dense />
            </v-col>

            <v-col cols="6">
            </v-col>

            <v-col cols="6">
              <LazyDropdown url="customers" v-model="customer" label="Customer" item-text="name" item-value="xid" outlined dense />
            </v-col>

            <v-col cols="6">
              <template v-if="customer">
                <LazyDropdown v-if='customer' :url="'/customers/'+customer.xid+'/staff'" v-model="contact" placeholder="Contact" :item-text="({user})=>`${user.name_first} ${user.name_last}`" item-value="xid" outlined dense />
              </template>
            </v-col>

            <v-col cols="6">
              <v-select v-model="opened" :items="openedChoices" label="Date Opened" outlined dense clearable hide-details />
              <template v-if="opened == 'Range'">
                <v-menu v-model="opened_show" :close-on-content-click="false" offset-y max-width="290px" min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field outlined dense hide-details class="mt-2" :value="opened_date_formatted" v-on="on" v-bind="attrs" readonly />
                  </template>
                  <v-date-picker range v-model="opened_range" no-title />
                </v-menu>
              </template>
            </v-col>

            <v-col cols="6">
              <v-select v-model="due" :items="dueChoices" label="Date Due" outlined dense clearable hide-details />
              <template v-if="due == 'Range'">
                <v-menu v-model="due_show" :close-on-content-click="false" offset-y max-width="290px" min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field outlined dense hide-details class="mt-2" :value="due_date_formatted" v-on="on" v-bind="attrs" readonly />
                  </template>
                  <v-date-picker range v-model="due_range" no-title />
                </v-menu>
              </template>
            </v-col>

            <v-col cols="6">
              <LazyDropdown url="jobStatus" v-model="status" label="Current Status" item-text="name" item-value="xid" outlined dense />
            </v-col>

            <v-col cols="6">
              <v-select v-model="status_updated" :items="status_updated_choices" label="Status Changed" outlined dense clearable hide-details />
              <template v-if="status_updated == 'Range'">
                <v-menu v-model="status_updated_show" :close-on-content-click="false" offset-y max-width="290px" min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field outlined dense hide-details class="mt-2" :value="status_updated_range_formatted" v-on="on" v-bind="attrs" readonly />
                  </template>
                  <v-date-picker range v-model="status_updated_datepicker" no-title />
                </v-menu>
              </template>
            </v-col>

            <v-col cols="6">
              <LazyDropdown url="qms/steps" v-model="step" label="Manufacture Step" item-text="title" item-value="xid" outlined dense />
            </v-col>

            <v-col cols="6"></v-col>

            <v-col cols="6">
              <LazyDropdown url="dispatch/status" v-model="dispatch" label="Current Despatch Status" item-text="status" item-value="xid" outlined dense />
            </v-col>

            <v-col cols="6">
              <v-select v-model="dispatch_updated" :items="status_updated_choices" label="Last Changed" outlined dense clearable hide-details />
              <template v-if="dispatch_updated == 'Range'">
                <v-menu v-model="dispatch_updated_show" :close-on-content-click="false" offset-y max-width="290px" min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field outlined dense hide-details class="mt-2" :value="dispatch_updated_range_formatted" v-on="on" v-bind="attrs" readonly />
                  </template>
                  <v-date-picker range v-model="dispatch_updated_datepicker" no-title />
                </v-menu>
              </template>
            </v-col>

            <v-col cols="6">
              <LazyDropdown url="billing/status" v-model="payment_status" label="Current Billing Status" item-text="status" item-value="xid" outlined dense />
            </v-col>

            <v-col cols="6">
              <v-select v-model="payment_updated" :items="status_updated_choices" label="Last Changed" outlined dense clearable hide-details />
              <template v-if="payment_updated == 'Range'">
                <v-menu v-model="payment_updated_show" :close-on-content-click="false" offset-y max-width="290px" min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field outlined dense hide-details class="mt-2" :value="payment_updated_range_formatted" v-on="on" v-bind="attrs" readonly />
                  </template>
                  <v-date-picker range v-model="payment_updated_datepicker" no-title />
                </v-menu>
              </template>
            </v-col>

          </v-row>

        </v-card-text>
        <v-divider />
        <v-card-actions class="grey lighten-3">
          <v-spacer />
          <v-btn class="primary" @click="clickDone">Done</v-btn>          
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss">
//@import "@/assets/common";
</style>

<script>
import LazyDropdown from '@/components/LazyDropdown'
import moment from 'moment'
export default {
  name: 'JobFilters',

  components: {
    LazyDropdown
  },

  data: () => ({
    isOpen: false,

    job_type: null,
    customer: null,
    contact: null,
    status: null,
    step: null,
    opened: null,
    opened_range: [],
    due: null,
    due_range: [],
    status_updated: null,
    status_updated_datepicker: [],
    
    dispatch: null,
    dispatch_updated: null,
    dispatch_updated_show: false,
    dispatch_updated_datepicker: [],

    payment_status: null,
    payment_updated: null,
    payment_updated_show: false,
    payment_updated_datepicker: [],



    openedChoices: ["Today", "Yesterday", "This Week", "Last Week", "Range"],
    dueChoices: ["Overdue", "Today", "Tomorrow", "This Week", "Next Week", "Range"],
    status_updated_choices: ["Today", "Yesterday", "This Week", "Last Week", "Range"],

    opened_show: false,
    due_show: false,



  }),

  computed: {
    opened_date_range(){ return [...this.opened_range ?? []].sort(); },
    opened_date_formatted(){ return this.opened_date_range.map(d => this.formatDate(d)).join(' - '); },
    due_date_range(){ return [...this.due_range ?? []].sort(); },
    due_date_formatted(){ return this.due_date_range.map(d => this.formatDate(d)).join(' - '); },
    status_updated_range(){ return [...this.status_updated_datepicker ?? []].sort(); },
    status_updated_range_formatted(){ return this.status_updated_range.map(d => this.formatDate(d)).join(' - '); },
    dispatch_updated_range(){ return [...this.dispatch_updated_datepicker ?? []].sort(); },
    dispatch_updated_range_formatted(){ return this.dispatch_updated_range.map(d => this.formatDate(d)).join(' - '); },
    payment_updated_range(){ return [...this.payment_updated_datepicker ?? []].sort(); },
    payment_updated_range_formatted(){ return this.payment_updated_range.map(d => this.formatDate(d)).join(' - '); },
  },

  watch: {
    customer(){ this.contact = null; },
  },

  methods:{

    open(){
      this.isOpen = true;
    },

    clickDone(){
      this.isOpen = false;
      this.$emit('filter', {
        job_type: this.job_type?.xid,
        customer: this.customer?.xid,
        contact: this.contact?.xid,
        status: this.status?.xid,
        step: this.step?.xid,
        opened: this.opened,
        opened_range: this.opened_range,
        due: this.due,
        due_range: this.due_range,
        status_updated: this.status_updated,
        status_updated_range: this.status_updated_range,
        despatch: this.dispatch?.xid,
        despatch_updated: this.dispatch_updated,
        despatch_updated_range: this.dispatch_updated_range,
        payment_status: this.payment_status?.xid,
        payment_updated: this.payment_updated,
        payment_updated_range: this.payment_updated_range,
      });
    },

    formatDate(date){ return moment(date).format("DD/MM/YY"); }

  },

  mounted() {
  },

};
</script>
